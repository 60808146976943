
.Progress{

    display: flex;
    padding-bottom: 5px ;
    border-radius: 6px;
    outline: 1px solid
    transparent; 
}

.Progress-item {
    outline: 2px solid transparent;
}